import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  activity: icon('ic_activity'),
  realTime: icon('ic_real_time'),
  employee: icon('ic_employee'),
  attendance: icon('ic_attendance'),
  apps: icon('ic_apps'),
  reports: icon('ic_reports'),
  teams: icon('ic_teams'),
  clients: icon('ic_clients'),
  projects: icon('ic_projects'),
  users: icon('ic_users'),
};

// // ----------------------------------------------------------------------

// export function useNavData() {
//   const { t } = useLocales();
//   const { user } = useAuthContext();
//   console.log('userData', user);
//   const generateMenuConfig = (userRole) => {
//     switch (userRole) {
//       case 'ADMIN':
//         return [
//           {
//             subheader: t('overview'),
//             items: [
//               {
//                 title: t('dashboard'),
//                 path: userRole === 'ADMIN' ? paths.dashboard.root : undefined,
//                 icon: ICONS.dashboard,
//               },
// {
//   title: t('productivity'),
//   path: paths.dashboard.general.productivity,
//   icon: ICONS.analytics,
// },

// {
//   title: t('screenshots'),
//   path: paths.dashboard.general.screenshots,
//   icon: ICONS.booking,
// },
// {
//   title: t('productivityTrends'),
//   path: paths.dashboard.general.productivityTrends,
//   icon: ICONS.analytics,
// },
// {
//   title: t('calendar'),
//   path: paths.dashboard.calendar,
//   icon: ICONS.calendar,
// },

// {
//   title: t('Activity'),
//   path: paths.dashboard.general.activity,
//   icon: ICONS.activity,
// },
// {
//   title: t('dashboard'),
//   path: paths.dashboard.root,
//   icon: ICONS.dashboard,
// },
// {
//   title: t('productivity'),
//   path: paths.dashboard.general.ecommerce,
//   icon: ICONS.analytics,
// },
// {
//   title: t('real-time insight'),
//   path: paths.dashboard.general.analytics,
//   icon: ICONS.analytics,
// },

// {
//   title: t('employees'),
//   path: paths.dashboard.general.banking,
//   icon: ICONS.banking,
// },
// {
//   title: t('screenshots'),
//   path: userRole === 'ADMIN' ? paths.dashboard.general.screenshots : undefined,
//   icon: ICONS.booking,
//   // roles: ['ADMIN'],
// },
// {
//   title: t('attendance'),
//   path: paths.dashboard.general.file,
//   icon: ICONS.file,
// },
//             ],
//           },
//           {
//             subheader: t('management'),
//             items: [
//               {
//                 title: t('real-time insight'),
//                 path: paths.dashboard.general.realtimeInsight,
//                 icon: ICONS.realTime,
//               },
//               {
//                 title: t('employees'),
//                 path: userRole === 'ADMIN' ? paths.dashboard.general.employee : undefined,
//                 icon: ICONS.employee,
//               },
//               {
//                 title: t('attendance'),
//                 path: userRole === 'ADMIN' ? paths.dashboard.general.attendance : undefined,
//                 icon: ICONS.attendance,
//               },
//               {
//                 title: t('apps & websites'),
//                 path: paths.dashboard.general.appswebsites,
//                 icon: ICONS.apps,
//               },
//               {
//                 title: t('Reports'),
//                 path: paths.dashboard.general.reports,
//                 icon: ICONS.reports,
//               },
//               {
//                 title: t('Team'),
//                 path: paths.dashboard.general.team,
//                 icon: ICONS.teams,
//               },
//               {
//                 title: t('Clients'),
//                 path: paths.dashboard.general.clients,
//                 icon: ICONS.clients,
//               },
//               {
//                 title: t('Projects'),
//                 path: paths.dashboard.general.projects,
//                 icon: ICONS.projects,
//               },
//               {
//                 title: t('Users'),
//                 path: paths.dashboard.general.users,
//                 icon: ICONS.users,
//               },
//               { title: t('profile'), path: paths.dashboard.user.root },
//               { title: t('cards'), path: paths.dashboard.user.cards },
//               { title: t('list'), path: paths.dashboard.user.list },
//               { title: t('create'), path: paths.dashboard.user.new },
//               { title: t('edit'), path: paths.dashboard.user.demo.edit },
//               { title: t('account'), path: paths.dashboard.user.account },
//               { title: t('list'), path: paths.dashboard.product.root },
//               {
//                 title: t('details'),
//                 path: paths.dashboard.product.demo.details,
//               },
//               { title: t('create'), path: paths.dashboard.product.new },
//               { title: t('edit'), path: paths.dashboard.product.demo.edit },
//               { title: t('list'), path: paths.dashboard.order.root },
//               { title: t('details'), path: paths.dashboard.order.demo.details },
//               { title: t('list'), path: paths.dashboard.invoice.root },
//               {
//                 title: t('details'),
//                 path: paths.dashboard.invoice.demo.details,
//               },
//               { title: t('create'), path: paths.dashboard.invoice.new },
//               { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
//               {
//                 title: t('file_manager'),
//                 path: paths.dashboard.fileManager,
//                 icon: ICONS.folder,
//               },

//               // MAIL
//               {
//                 title: t('mail'),
//                 path: paths.dashboard.mail,
//                 icon: ICONS.mail,
//                 info: <Label color="error">+32</Label>,
//               },

//               // CHAT
//               {
//                 title: t('chat'),
//                 path: paths.dashboard.chat,
//                 icon: ICONS.chat,
//               },

//               // CALENDAR
//               {
//                 title: t('calendar'),
//                 path: paths.dashboard.calendar,
//                 icon: ICONS.calendar,
//               },

//               // KANBAN
//               {
//                 title: t('kanban'),
//                 path: paths.dashboard.kanban,
//                 icon: ICONS.kanban,
//               },
//             ],
//           },
//         ];

//       case 'USER':
//         return [
//           {
//             subheader: t('overview'),
//             items: [
//               {
//                 title: t('dashboard'),
//                 path: userRole === 'USER' ? paths.dashboard.root : undefined,
//                 icon: ICONS.dashboard,
//               },
//               {
//                 title: t('productivity'),
//                 path: userRole === 'USER' ? paths.dashboard.general.productivity : undefined,
//                 icon: ICONS.analytics,
//               },
//               {
//                 title: t('calendar'),
//                 path: userRole === 'USER' ? paths.dashboard.calendar : undefined,
//                 icon: ICONS.calendar,
//               },
//             ],
//           },
//         ];

//       case 'SUPER':
//         return [
//           {
//             subheader: t('overview'),
//             items: [
//               {
//                 title: t('dashboard'),
//                 path: paths.dashboard.root,
//                 icon: ICONS.dashboard,
//               },
//               {
//                 title: t('productivity'),
//                 path: paths.dashboard.general.productivity,
//                 icon: ICONS.analytics,
//               },
//               {
//                 title: t('calendar'),
//                 path: paths.dashboard.calendar,
//                 icon: ICONS.calendar,
//               },
//               {
//                 title: t('customers'),
//                 path: paths.dashboard.general.banking,
//                 icon: ICONS.banking,
//               },
//               {
//                 title: t('reports'),
//                 path: paths.dashboard.general.booking,
//                 icon: ICONS.booking,
//               },
//               {
//                 title: t('Activity'),
//                 path: paths.dashboard.general.activity,
//                 icon: ICONS.activity,
//               },
//               {
//                 title: t('clients'),
//                 path: paths.dashboard.general.file,
//                 icon: ICONS.file,
//               },
//               {
//                 title: t('projects'),
//                 path: paths.dashboard.general.file,
//                 icon: ICONS.file,
//               },
//               {
//                 title: t('users'),
//                 path: paths.dashboard.general.file,
//                 icon: ICONS.file,
//               },
//             ],
//           },
//           {
//             subheader: t('management'),
//             items: [
//               {
//                 title: t('reports'),
//                 path: paths.dashboard.general.booking,
//                 icon: ICONS.booking,
//               },

//               {
//                 title: t('clients'),
//                 path: paths.dashboard.general.file,
//                 icon: ICONS.file,
//               },
//               {
//                 title: t('projects'),
//                 path: paths.dashboard.general.file,
//                 icon: ICONS.file,
//               },
//               {
//                 title: t('users'),
//                 path: paths.dashboard.general.file,
//                 icon: ICONS.file,
//               },
//             ],
//           },
//         ];

//       case 'SUPERVISOR':
//         return [
//           {
//             subheader: t('overview'),
//             items: [
//               {
//                 title: t('dashboard'),
//                 path: paths.dashboard.root,
//                 icon: ICONS.dashboard,
//               },
//               {
//                 title: t('productivity'),
//                 path: paths.dashboard.general.productivity,
//                 icon: ICONS.analytics,
//               },
//               {
//                 title: t('screenshots'),
//                 path: paths.dashboard.general.screenshots,
//                 icon: ICONS.booking,
//               },
//               {
//                 title: t('productivityTrends'),
//                 path: paths.dashboard.general.productivityTrends,
//                 icon: ICONS.booking,
//               },
//             ],
//           },
//           {
//             subheader: t('management'),
//             items: [
//               {
//                 title: t('real-time insight'),
//                 path: paths.dashboard.general.realtimeInsight,
//                 icon: ICONS.realTime,
//               },
//               {
//                 title: t('employees'),
//                 path: paths.dashboard.general.employee,
//                 icon: ICONS.employee,
//               },
//               {
//                 title: t('attendance'),
//                 path: paths.dashboard.general.attendance,
//                 icon: ICONS.attendance,
//               },
//             ],
//           },
//         ];
//       default:
//         return [];
//     }
//   };

//   const userRole = user?.role?.role; // Assuming `user` directly contains the role information
//   const userConfig = useMemo(() => generateMenuConfig(userRole), [userRole, t]);
//   const data = useMemo(() => userConfig, [userConfig]);

//   return data;
// }

export function useNavData() {
  const { t } = useLocales();
  const { user } = useAuthContext();
  console.log('filterItems', user);
  const filterItems = (items) => {
    return items.reduce((filtered, item) => {
      // Check if the item is in the pageAccess array and is visible
      const pageAccessItem = user?.pageAccess?.find(
        (accessItem) => accessItem.pageName === item.title
      );

      // Check if the page is present and visible in the pageAccess array
      if (pageAccessItem && pageAccessItem.access.visible) {
        // Include the item with correct path and icon
        filtered.push({
          title: item.title,
          path: user?.pageAccess?.some((accessItem) => accessItem.pageName === item.title)
            ? item.path
            : undefined,
          icon: item.icon,
        });
      }

      return filtered;
    }, []);
  };

  const generateMenuConfig = () => {
    return [
      {
        subheader: t('Trends Overview'),
        items: filterItems([
          {
            title: t('Dashboard'),
            path: user?.pageAccess?.some((accessItem) => accessItem.pageName === 'Dashboard')
              ? paths.dashboard.root
              : undefined,
            icon: ICONS.dashboard,
          },
          {
            title: t('Productivity Trend'),
            path: user?.pageAccess?.some(
              (accessItem) => accessItem.pageName === 'Productivity Trend'
            )
              ? paths.dashboard.general.productivityTrends
              : undefined,
            icon: ICONS.analytics,
          },

          {
            title: t('Calender'),
            path: user?.pageAccess?.some((accessItem) => accessItem.pageName === 'Calender')
              ? paths.dashboard.calendar
              : undefined,
            icon: ICONS.calendar,
          },

          {
            title: t('Activities'),
            path: user?.pageAccess?.some((accessItem) => accessItem.pageName === 'Activities')
              ? paths.dashboard.general.activity
              : undefined,
            icon: ICONS.activity,
          },
        ]),
      },
      {
        subheader: t('track status'),
        items: filterItems([
          {
            title: t('Real-Time Insight'),
            path: user?.pageAccess?.some(
              (accessItem) => accessItem.pageName === 'Real-Time Insight'
            )
              ? paths.dashboard.general.realtimeInsight
              : undefined,
            icon: ICONS.realTime,
          },

          {
            title: t('Timesheet'),
            path: user?.pageAccess?.some((accessItem) => accessItem.pageName === 'Timesheet')
              ? paths.dashboard.general.timesheets
              : undefined,
            icon: ICONS.employee,
          },

          {
            title: t('Reports'),
            path: user?.pageAccess?.some((accessItem) => accessItem.pageName === 'Reports')
              ? paths.dashboard.general.reports
              : undefined,
            icon: ICONS.reports,
          },
        ]),
      },
      {
        subheader: t('gallery & statistics'),
        items: filterItems([
          {
            title: t('ScreenShots'),
            path: user?.pageAccess?.some((accessItem) => accessItem.pageName === 'ScreenShots')
              ? paths.dashboard.general.screenshots
              : undefined,
            icon: ICONS.booking,
          },
          {
            title: t('Apps & Websites'),
            path: user?.pageAccess?.some((accessItem) => accessItem.pageName === 'Apps & Websites')
              ? paths.dashboard.general.appswebsites
              : undefined,
            icon: ICONS.apps,
          },
        ]),
      },
      {
        subheader: t('management'),
        items: filterItems([
          {
            title: t('Clients'),
            path: user?.pageAccess?.some((accessItem) => accessItem.pageName === 'Clients')
              ? paths.dashboard.general.clients
              : undefined,
            icon: ICONS.clients,
          },
          {
            title: t('Users'),
            path: user?.pageAccess?.some((accessItem) => accessItem.pageName === 'Users')
              ? paths.dashboard.general.users
              : undefined,
            icon: ICONS.users,
          },
          {
            title: t('Teams'),
            path: user?.pageAccess?.some((accessItem) => accessItem.pageName === 'Teams')
              ? paths.dashboard.general.team
              : undefined,
            icon: ICONS.teams,
          },
          {
            title: t('Time & Attendance'),
            path: user?.pageAccess?.some(
              (accessItem) => accessItem.pageName === 'Time & Attendance'
            )
              ? paths.dashboard.general.attendance
              : undefined,
            icon: ICONS.attendance,
          },

          {
            title: t('Projects'),
            path: user?.pageAccess?.some((accessItem) => accessItem.pageName === 'Projects')
              ? paths.dashboard.general.projects
              : undefined,
            icon: ICONS.projects,
          },
        ]),
      },
    ];
  };

  const userConfig = useMemo(() => generateMenuConfig(), [user?.pageAccess, t]);
  const data = useMemo(() => userConfig, [userConfig]);

  return data;
}
