import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import usePageAccess from './usepageAccess';
import { View403 } from 'src/sections/error';
import TimesheetTable from 'src/sections/report/timesheet-table';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const AppsAndWebsitePage = lazy(() => import('src/pages/dashboard/apps&website'));
const ProjectsPage = lazy(() => import('src/pages/dashboard/projects-list'));
const ClientsViewPage = lazy(() => import('src/pages/dashboard/clients'));
const TimeAttendanceTabView = lazy(() => import('src/pages/dashboard/time&attendance/attendance'));
const TimeSheetsView = lazy(() => import('src/pages/dashboard/timesheet-view'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewScreenshotPage = lazy(() => import('src/pages/dashboard/screenshots'));
const OverviewProductivityTrendsPage = lazy(() => import('src/pages/dashboard/ProductivityTrends'));
const ProductivityUserPage = lazy(() => import('src/pages/dashboard/ProductivityTrends'));
const RealTimeInsight = lazy(() => import('src/pages/dashboard/realTime'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
const UsersList = lazy(() => import('src/pages/dashboard/user-list'));
const TeamList = lazy(() => import('src/pages/dashboard/teams'));
const ActivityPage = lazy(() => import('src/pages/dashboard/activity'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const TimesheetPage = lazy(() => import('src/pages/dashboard/reports/timesheets'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const ProductivityTabPage = lazy(() => import('src/pages/dashboard/user/productivity-tab'));
const UserProductivityPage = lazy(() => import('src/sections/user/user-productivity'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------
const ConditionalRender = ({ component, requiredRole }) => {
  const hasAccess = usePageAccess(requiredRole);

  if (!hasAccess) {
    return <View403 />;
  }

  return component;
};
export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'screenshots', element: <OverviewScreenshotPage /> },
      {
        path: 'real-timeInsight',
        element: (
          <ConditionalRender component={<RealTimeInsight />} requiredRole="Real-Time Insight" />
        ),
      },

      { path: 'apps&websites', element: <AppsAndWebsitePage /> },
      { path: 'activity', element: <ActivityPage /> },

      { path: 'projects', element: <ProjectsPage /> },
      { path: 'clients', element: <ClientsViewPage /> },
      { path: 'attendance', element: <TimesheetTable /> },
      { path: 'timesheets', element: <TimeSheetsView /> },
      // { path: 'attendance', element: <TimesheetTable /> },
      { path: 'reports', element: <TimeAttendanceTabView /> },

      {
        path: 'users',
        element: <ConditionalRender component={<UsersList />} requiredRole="Users" />,
      },
      {
        path: 'teams',
        element: <ConditionalRender component={<TeamList />} requiredRole="Users" />,
      },
      { path: 'ProductivityTrends', element: <OverviewProductivityTrendsPage /> },

      // { path: 'users', element: <UsersList /> },

      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
          {
            path: 'user-productivity',
            element: (
              <ConditionalRender
                component={<UserProductivityPage />}
                requiredRole="User Productivity"
              />
            ),
          },
          { path: 'productivity-tab', element: <ProductivityTabPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
