import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import EmptyContent from '../empty-content';

// ----------------------------------------------------------------------

const emptyMessages = [
  // 'Whoops! Looks like this table is as empty as a black hole 🕳️',
  // "Uh-oh! It seems we've hit a data dead-end. Time to put on our detective hats 🕵️‍♂️",
  // 'Data abyss! Refine your search.',
  // 'No results found. Try different keywords.',
  // 'Data not found. Try different keywords.',
  // 'Looks like the data fairy has gone on vacation 🧚‍♀️',
  // "Houston, we have a problem! 🚀 Our data rocket couldn't find any matching keywords in this vast universe.",
  // 'Uh-oh! No data found. Time to adjust those keywords!',
  // 'Data drought! Adjust keywords to find oasis.',

  'No data found',
];

export default function TableNoData({ notFound, sx }) {
  const randomMessage = useMemo(() => {
    if (notFound) {
      const randomIndex = Math.floor(Math.random() * emptyMessages.length);
      return emptyMessages[randomIndex];
    }
    return '';
  }, [notFound]);

  return (
    <TableRow>
      {notFound ? (
        <TableCell colSpan={12}>
          <EmptyContent
            filled
            title={randomMessage}
            sx={{
              py: 10,
              ...sx,
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}

TableNoData.propTypes = {
  notFound: PropTypes.bool,
  sx: PropTypes.object,
};

TableNoData.propTypes = {
  notFound: PropTypes.bool,
  sx: PropTypes.object,
};
