import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import axios, { endpoints } from 'src/utils/axios';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import { paths } from 'src/routes/paths';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { Crypto } from 'src/redux/secureStorage';

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate(); // Use useNavigation hook
  const { enqueueSnackbar } = useSnackbar();

  const initialize = useCallback(async () => {
    try {
      const accessToken = Crypto.getItem('accessToken');
      const userData = Crypto.getItem('userData');
      console.log('userData', userData);
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user: userData,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);
  const login = async (email, password) => {
    const data = {
      email,
      password,
    };
    try {
      const response = await axios.post(endpoints.auth.login, data);
    } catch (err) {
      throw new Error(err?.message);
    }
  };
  const register = async (data) => {
    try {
      const response = await axios.post('/users', data);
      navigate(paths?.auth?.jwt?.verify);
    } catch (err) {
      enqueueSnackbar(err?.message, { variant: 'error' });
      throw new Error(err?.message);
    }
  };
  const updateUserData = async (data) => {
    Crypto.setItem('userData', data);
    dispatch({ type: 'INITIALIZE', payload: { isAuthenticated: true, user: data } });
  };
  const otpVerification = async (data) => {
    sessionStorage.setItem('accessToken', data.token);
    sessionStorage.setItem('userData', data?.role?.role);
    Crypto.setItem('accessToken', data.token);
    Crypto.setItem('userData', data);
    Crypto.setItem('mainId', data.main);

    setSession(data.token);
    enqueueSnackbar('Verified', { variant: 'info' });
    navigate(paths?.dashboard.general.productivityTrends);
    dispatch({ type: 'INITIALIZE', payload: { isAuthenticated: true, user: data } });

    window.location.reload();
  };
  const logout = useCallback(async () => {
    setSession(null);
    Crypto.clear();
    window.localStorage.clear();
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const checkAuthenticated = state?.user ? 'authenticated' : 'unauthenticated';
  const status = state?.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      login,
      register,
      otpVerification,
      updateUserData,
      logout,
    }),
    [login, logout, register, state.user, status, otpVerification, updateUserData]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
