import { createSlice } from '@reduxjs/toolkit';
// utils
import { httpGet, httpPost, httpPut } from '../../utils/apis';
import { dispatch } from '../store';
import { Crypto } from '../secureStorage';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  stripeIntent: {},

  timerData: Crypto.getItem('timerData')
    ? Crypto.getItem('timerData')
    : {
        description: '',
        trackingId: '',
        timer: '',
      },
  projectList: [],
  activityList: [],
  notificationList: [],
  planModules: [],
  companyPlanList: [],
  currentPlanDetails: [],
  upgradeId: null,
  plansList: [],
};

const slice = createSlice({
  name: 'commonReducer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // GET PRODUCTS
    SetTimer(state, action) {
      state.isLoading = false;
      state.timer = action.payload;
    },
    setTrackingData(state, action) {
      console.log('Aciton', action);
      Crypto.setItem('timerData', action.payload);
      state.timerData = action.payload;
    },
    stopTracking(state, action) {
      Crypto.removeItem('timerData');
      state.timerData = {};
    },
    setProjectList(state, action) {
      state.projectList = action.payload;
    },
    stripeIntentRedux(state, action) {
      state.stripeIntent = action.payload;
    },
    setActivityList(state, action) {
      state.activityList = action.payload;
    },
    setNotificationList(state, action) {
      state.notificationList = action.payload;
    },
    setPlanModuleAccess(state, action) {
      state.planModules = action?.payload;
    },
    setCompanyPlanList(state, action) {
      state.companyPlanList = action?.payload;
    },
    setCurrentPlanDetails(state, action) {
      state.currentPlanDetails = action?.payload;
    },
    setUpGradeId(state, action) {
      state.upgradeId = action?.payload;
    },
    setPlansList(state, action) {
      state.plansList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  SetTimer,
  setTrackingData,
  setProjectList,
  stopTracking,
  setActivityList,
  setNotificationList,
} = slice.actions;

// ----------------------------------------------------------------------

export function fetchProjects(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const str = data || '';
      const response = await httpGet(`projects?filter=${str}`);
      const projectList = response?.data?.data.filter((item) => item.isActive === true && item);

      dispatch(slice.actions.setProjectList(projectList));
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
}

// // ----------------------------------------------------------------------
// ----------------------------------------------------------------------

export function fetchActivities(data, projectId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const str = data || '';
      let projId = {};
      console.log('projectId:::::', projId);
      if (projectId !== '-1' && projectId !== null) {
        projId = { project: projectId };
      }
      const response = await httpPost('activity/getActivity', projId);

      const activityList = response?.data?.data.filter((item) => item.isActive === true && item);

      dispatch(slice.actions.setActivityList(activityList));
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
}

// // ----------------------------------------------------------------------
export function fetchNotification(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await httpGet('alert/getNotification');

      dispatch(slice.actions.setNotificationList(response?.data?.data));
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateOrder(data) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await httpPut(`${process.env.REACT_APP_PAYMENT_API}/orders/capture`, data);
      // Crypto.removeItem('pData');

      return response?.data?.data;
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      // dispatch(slice.actions.stopLoading());
      throw new Error(error.message);
    }
  };
}

export function activatePlan(data) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await httpPost(`${process.env.REACT_APP_PAYMENT_API}/orders/activate`, data);
      return response?.data?.data;
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export const companyPlanUsage = () => {
  return async () => {
    try {
      const res = await httpPost(`${process.env.REACT_APP_PAYMENT_API}/metrics/current`, {
        main: Crypto.getItem('mainId'),
      });
      return res?.data?.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
};
export const companyPlanDetails = (stateData) => {
  return async () => {
    try {
      const res = await httpPost(`${process.env.REACT_APP_PAYMENT_API}/orders/current`, {
        main: Crypto.getItem('mainId') || stateData?._id,
        context: stateData ? stateData?.context : null,
      });

      const data = res?.data?.data;
      const combinedFeatures = [];

      // Iterate over each object in the cart array
      data[0]?.cart?.forEach((item) => {
        // Iterate over each module in the current item's modules array
        item?.modules?.forEach((module) => {
          // Iterate over each feature in the current module's features array
          module?.features?.forEach((feature) => {
            // Push the feature into the combinedFeatures array
            combinedFeatures.push({
              planName: item?.planName,
              moduleName: module?.name,
              featureName: feature?.name,
              key: feature?.key || 'NOT_AVAILABLE',
              flag: feature?.flag,
            });
          });
        });
      });

      const upgradeId = data[0]?.paymentId?._id;
      const mycurrentPlanDetails = data[0];
      dispatch(slice.actions.setPlanModuleAccess(combinedFeatures));
      dispatch(slice.actions.setCompanyPlanList(data));
      dispatch(slice.actions.setCurrentPlanDetails(mycurrentPlanDetails));
      dispatch(slice.actions.setUpGradeId(upgradeId));

      // return res?.data?.data;
    } catch (error) {
      console.error('error :>> ', error);
      throw new Error(error.message);
    }
  };
};
export function ListSassPlans(data) {
  return async () => {
    try {
      const response = await httpGet(
        `${process.env.REACT_APP_PAYMENT_API}/plans/list?Product=Productivity`
      );
      dispatch(slice.actions.setPlansList(response?.data?.data || []));
      return response?.data?.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));

      throw new Error(error.message);
    }
  };
}
