import axios from './axios';

const handleResponse = (res) => {
  return { code: 1, data: res.data };
};

const handleError = (err) => {
  const status = err?.response?.status;
  if (status) {
    throw new Error(err?.response?.data?.message);
  }
  throw new Error(err?.message);
};

export const httpPost = async (url, data) => {
  try {
    const res = await axios.post(url, data);
    return handleResponse(res);
  } catch (err) {
    handleError(err);
  }
};

export const httpGet = async (url) => {
  try {
    const res = await axios.get(url);
    return handleResponse(res);
  } catch (err) {
    handleError(err);
  }
};

export const httpPut = async (url, data) => {
  try {
    const res = await axios.put(url, data);
    return handleResponse(res);
  } catch (err) {
    handleError(err);
  }
};

export const httpDelete = async (url, data) => {
  try {
    const res = await axios.delete(url, { data });
    return handleResponse(res);
  } catch (err) {
    handleError(err);
  }
};

export const httpPatch = async (url, data) => {
  try {
    const res = await axios.patch(url, data);
    return handleResponse(res);
  } catch (err) {
    handleError(err);
  }
};
