import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box sx={{ width: 100, height: 100, ...sx }}>
      <svg
        width="auto"
        height="auto"
        viewBox="0 0 136 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect fill={PRIMARY_MAIN} width="32" height="32" rx="8" />
        <path
          d="M11.8867 8.51562C11.8164 8.44531 11.6758 8.375 11.6055 8.375C11.5 8.375 11.3594 8.44531 11.2891 8.51562L9.03906 10.7305L8.26562 9.95703C8.19531 9.88672 8.05469 9.85156 7.94922 9.85156C7.84375 9.85156 7.73828 9.88672 7.66797 9.95703L7.10547 10.5195C7.03516 10.5898 7 10.7305 7 10.8008C7 10.9062 7.03516 11.0469 7.10547 11.1172L8.79297 12.7695C8.86328 12.8398 9.00391 12.9102 9.07422 12.9102C9.17969 12.9102 9.32031 12.8398 9.39062 12.7695L9.95312 12.2422L12.4844 9.67578C12.5547 9.64062 12.625 9.5 12.625 9.39453C12.625 9.28906 12.5547 9.14844 12.4844 9.07812L11.8867 8.51562ZM11.8867 14.1055C11.8164 14.0352 11.6758 14 11.6055 14C11.5 14 11.3594 14.0352 11.2891 14.1055L9.03906 16.3555L8.26562 15.582C8.19531 15.5117 8.05469 15.4414 7.94922 15.4414C7.84375 15.4414 7.73828 15.5117 7.66797 15.582L7.10547 16.1094C7.03516 16.1797 7 16.3203 7 16.4258C7 16.5312 7.03516 16.6719 7.10547 16.707L8.79297 18.3945C8.86328 18.4648 8.96875 18.5352 9.07422 18.5352C9.17969 18.5352 9.32031 18.4648 9.39062 18.3945L9.95312 17.832L12.4844 15.3008C12.5547 15.2305 12.625 15.0898 12.625 15.0195C12.625 14.9141 12.5547 14.7734 12.4844 14.7031L11.8867 14.1055ZM9.25 20.1875C8.30078 20.1875 7.52734 20.9609 7.52734 21.875C7.52734 22.8242 8.30078 23.5625 9.25 23.5625C10.1641 23.5625 10.9375 22.8242 10.9375 21.875C10.9375 20.9609 10.1641 20.1875 9.25 20.1875ZM24.4375 20.75H14.3125C13.9961 20.75 13.75 21.0312 13.75 21.3125V22.4375C13.75 22.7539 13.9961 23 14.3125 23H24.4375C24.7188 23 25 22.7539 25 22.4375V21.3125C25 21.0312 24.7188 20.75 24.4375 20.75ZM24.4375 9.5H14.3125C13.9961 9.5 13.75 9.78125 13.75 10.0625V11.1875C13.75 11.5039 13.9961 11.75 14.3125 11.75H24.4375C24.7188 11.75 25 11.5039 25 11.1875V10.0625C25 9.78125 24.7188 9.5 24.4375 9.5ZM24.4375 15.125H14.3125C13.9961 15.125 13.75 15.4062 13.75 15.6875V16.8125C13.75 17.1289 13.9961 17.375 14.3125 17.375H24.4375C24.7188 17.375 25 17.1289 25 16.8125V15.6875C25 15.4062 24.7188 15.125 24.4375 15.125Z"
          fill={'white'}
        />
        <path
          d="M43.0351 24.7568V11.1351H38V7H53.0811V11.1351H48.0459V24.7568H43.0351Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M52.5547 24.7568V12.1081H57.1277L57.1763 14.2973H57.225C57.7114 13.7622 58.2304 13.3162 58.7817 12.9595C59.3493 12.5865 59.9493 12.3108 60.5817 12.1324C61.2141 11.9541 61.8628 11.8649 62.5277 11.8649V15.7568C61.5871 15.7568 60.7196 15.9189 59.925 16.2432C59.1466 16.5676 58.5223 17.0054 58.052 17.5568C57.5979 18.1081 57.3709 18.7243 57.3709 19.4054V24.7568H52.5547Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M67.2365 25C66.0527 25 65.1041 24.6514 64.3906 23.9541C63.6933 23.2405 63.3446 22.2919 63.3446 21.1081C63.3446 19.7135 63.896 18.6351 64.9987 17.873C66.1014 17.1108 67.6581 16.7297 69.6689 16.7297H70.9824C70.9824 16.3243 70.9419 16.0162 70.8608 15.8054C70.796 15.5946 70.6581 15.4568 70.4473 15.3919C70.2365 15.3108 69.9284 15.2703 69.523 15.2703C68.7284 15.2703 67.8608 15.3514 66.9203 15.5135C65.9797 15.6757 65.1122 15.8946 64.3176 16.1703L63.8311 12.7649C64.7068 12.473 65.7041 12.2541 66.823 12.1081C67.9419 11.9459 69.0608 11.8649 70.1797 11.8649C71.5419 11.8649 72.6284 12.027 73.4392 12.3514C74.25 12.6595 74.8338 13.1703 75.1906 13.8838C75.5635 14.5973 75.75 15.5459 75.75 16.7297V24.7568H71.2257L71.177 23.2973H71.1284C70.6581 23.8324 70.0825 24.2541 69.4014 24.5622C68.7203 24.8541 67.9987 25 67.2365 25ZM69.0852 21.7162C69.4743 21.7162 69.8068 21.6595 70.0825 21.5459C70.3743 21.4162 70.5933 21.2459 70.7392 21.0351C70.9014 20.8081 70.9824 20.5486 70.9824 20.2568V19.2838H70.3987C69.4419 19.2838 68.7365 19.3892 68.2824 19.6C67.8446 19.8108 67.6257 20.1514 67.6257 20.6216C67.6257 20.9784 67.7473 21.2541 67.9906 21.4486C68.25 21.627 68.6149 21.7162 69.0852 21.7162Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M84.8764 25C83.3034 25 81.9575 24.7486 80.8386 24.2459C79.7359 23.727 78.8926 22.9811 78.3088 22.0081C77.7413 21.0189 77.4575 19.827 77.4575 18.4324C77.4575 17.0216 77.7413 15.8297 78.3088 14.8568C78.8926 13.8838 79.7359 13.1459 80.8386 12.6432C81.9575 12.1243 83.3034 11.8649 84.8764 11.8649C85.7034 11.8649 86.4575 11.9459 87.1385 12.1081C87.8196 12.2541 88.4845 12.4973 89.1331 12.8378L88.4034 16.2432C87.8196 15.9189 87.2764 15.6757 86.7737 15.5135C86.271 15.3514 85.7602 15.2703 85.2413 15.2703C84.3007 15.2703 83.5791 15.5378 83.0764 16.073C82.5737 16.6081 82.3223 17.3946 82.3223 18.4324C82.3223 19.4703 82.5737 20.2568 83.0764 20.7919C83.5791 21.327 84.3007 21.5946 85.2413 21.5946C85.7602 21.5946 86.271 21.5135 86.7737 21.3514C87.2764 21.1892 87.8196 20.9459 88.4034 20.6216L89.1331 24.027C88.4845 24.3676 87.8196 24.6108 87.1385 24.7568C86.4575 24.9189 85.7034 25 84.8764 25Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M91.4879 24.7568V7H96.2554V16.8514H96.3041L99.5149 12.1081H104.988L100.245 18.1892L105.109 24.7568H99.6365L96.3041 19.527H96.2554V24.7568H91.4879Z"
          fill={PRIMARY_MAIN}
        />
        <path d="M106.982 24.7568V7H112.091V24.7568H106.982Z" fill={PRIMARY_MAIN} />
        <path
          d="M115.005 24.7568V12.1081H119.529L119.578 13.5676H119.626C120.194 12.9676 120.794 12.5378 121.426 12.2784C122.059 12.0027 122.796 11.8649 123.64 11.8649C125.115 11.8649 126.194 12.2703 126.875 13.0811C127.556 13.8919 127.896 15.1892 127.896 16.973V24.7568H123.129V17.9459C123.129 17.1514 123.015 16.5919 122.788 16.2676C122.578 15.927 122.213 15.7568 121.694 15.7568C121.321 15.7568 120.988 15.8297 120.696 15.9757C120.405 16.1216 120.178 16.3243 120.015 16.5838C119.853 16.827 119.772 17.1189 119.772 17.4595V24.7568H115.005Z"
          fill={PRIMARY_MAIN}
        />
        <path d="M130.547 24.7568V19.8919H135.411V24.7568H130.547Z" fill={PRIMARY_MAIN} />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to={paths} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
