import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import { Box, FormHelperText } from '@mui/material';

// ----------------------------------------------------------------------

export default function RHFTextField({ name, helperText, type, ...other }) {
  const { control } = useFormContext();

  return (
    // <Controller
    //   name={name}
    //   control={control}
    //   render={({ field, fieldState: { error } }) => (
    //     <TextField
    //       {...field}
    //       fullWidth
    //       type={type}
    //       value={type === 'number' && field.value === 0 ? '' : field.value}
    //       onChange={(event) => {
    //         if (type === 'number') {
    //           field.onChange(Number(event.target.value));
    //         } else {
    //           field.onChange(event.target.value);
    //         }
    //       }}
    //       error={!!error}
    //       helperText={error ? error?.message : helperText}
    //       {...other}
    //     />
    //   )}
    // />
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box display="flex" flexDirection="column" alignItems="stretch">
          <TextField
            {...field}
            fullWidth
            type={type}
            value={type === 'number' && field.value === 0 ? '' : field.value}
            onChange={(event) => {
              if (type === 'number') {
                field.onChange(Number(event.target.value));
              } else {
                field.onChange(event.target.value);
              }
            }}
            helperText={error ? error?.message : helperText}
            error={!!error}
            {...other}
          />
          {/* {error && (
            <Box ml="0.5rem">
              <FormHelperText error>{error.message}</FormHelperText>
            </Box>
          )} */}
        </Box>
      )}
    />
  );
}

RHFTextField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
};
