// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import { useAuthContext } from 'src/auth/hooks';
import IconButton from '@mui/material/IconButton';
import { alpha } from '@mui/material/styles';
import { varHover } from 'src/components/animate';
import { m } from 'framer-motion';
import { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  // const { user } = useMockedUser();
  const { user } = useAuthContext();
  console.log('useLocales', user);
  const { t } = useLocales();
  const popover = usePopover();

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          {/* <Avatar
            src={user?.photoURL}
            alt={user?.displayName}
            sx={{
              width: 48,
              height: 48,
              border: (theme) => `solid 2px ${theme.palette.background.default}`,
            }}
          >
            {user?.fullName.charAt(0).toUpperCase()}
          </Avatar> */}
          <IconButton
            component={m.button}
            whileTap="tap"
            whileHover="hover"
            variants={varHover(1.05)}
            onClick={popover.onOpen}
            sx={{
              width: 50,
              height: 50,
              background: (theme) => alpha(theme.palette.grey[500], 0.08),
              ...(popover.open && {
                background: (theme) =>
                  `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
              }),
            }}
          >
            <Avatar
              src={user?.photoURL}
              alt={user?.fullName}
              sx={{
                width: 36,
                height: 36,
                border: (theme) => `solid 2px ${theme.palette.background.default}`,
              }}
            >
              {user?.fullName.charAt(0).toUpperCase()}
            </Avatar>
          </IconButton>
          {/* <Label
            color="success"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            Free
          </Label> */}
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.fullName}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {user?.email}
          </Typography>
        </Stack>

        {/* <Button variant="contained" href={paths.minimalUI} target="_blank" rel="noopener">
          {t('upgrade_to_pro')}
        </Button> */}
      </Stack>
    </Stack>
  );
}
