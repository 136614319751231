import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import {
  Card,
  Checkbox,
  Chip,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { httpGet, httpPost, httpPut } from 'src/utils/apis';
import ColorSinglePicker from 'src/components/color-utils/color-single-picker';
import { dispatch } from 'src/redux/store';
import { fetchProjects } from 'src/redux/slices/common';
import AddEditClient from '../overview/clients/addedit-Client';

const COLOR_OPTIONS = [
  '#00AB55', // theme.palette.primary.main,
  '#1890FF', // theme.palette.info.main,
  '#54D62C', // theme.palette.success.main,
  '#FFC107', // theme.palette.warning.main,
  '#FF4842', // theme.palette.error.main
  '#04297A', // theme.palette.info.darker
  '#7A0C2E', // theme.palette.error.darker
];

export default function ProjectQuickEditForm(props) {
  const [subClientList, setSubClientList] = useState(props.subClientList);
  const { enqueueSnackbar } = useSnackbar();
  const [manageToggleModal, setManageModal] = useState(false);
  const [wantToEdit, setWantToEdit] = useState(false);
  const [clientId, setClientId] = useState('');
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [formData, setFormData] = useState({});
  const [filterName, setFilterName] = useState('');
  const [tableData, setTableData] = useState([]);
  const [userList, setUserList] = useState([]);
  const ListClient = async () => {
    try {
      const res = await httpGet(`client?filter=${filterName.length > 4 ? filterName : ''}`);
      if (res.code === 1) {
        setTableData(res.data.data);
        enqueueSnackbar('New client created successfully', { variant: 'success' });
      } else if (res.code === 0) {
        enqueueSnackbar(res.message, { variant: 'error' });
      }
    } catch (error) {
      console.error(error, 'error');
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  const handleCloseManageModal = () => {
    setManageModal(false);
  };
  const defaultValue = useMemo(
    () => (props.formData ? props.formData : '-'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.formData]
  );
  const ProjectSchema = Yup.object().shape({
    projectName: Yup.string().required('Project Name is required'),
    client: Yup.string().required('Client Name is required'),
    estimatedTime: Yup.string()
      .required('Estimated Time is required')
      .matches(/^\d+$/, 'Only digits are allowed'),

    billableRate: Yup.string()
      .required('Budgeted Rates is required')
      .matches(/^\d+$/, 'Only digits are allowed'),
  });
  const methods = useForm({
    resolver: yupResolver(ProjectSchema),
    defaultValue,
  });
  const {
    reset,
    setError,
    setValue,
    watch,
    control,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting },
  } = methods;
  const values = watch();

  useEffect(() => {
    if (props.wantToEdit === true && props.formData) {
      reset(defaultValue);
    }
    if (props.wantToEdit === false) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.wantToEdit, props.formData]);

  const onSubmit = async (data) => {
    if (props.wantToEdit === true) {
      // EDIT API CALL
      delete data.isChecked;
      if (data.color === null) {
        delete data.color;
      }
      const obj = data;
      obj._id = props.projectId;
      try {
        const res = await httpPut('projects', obj);
        if (res.code === 1) {
          props.ListProject();
          props.setManageModal(false);
        } else if (res.code === 0) {
          props.enqueueSnackbar(res.message, { variant: 'error' });
        }
      } catch (error) {
        props.enqueueSnackbar(error.message, { variant: 'error' });
      }
    } else if (props.wantToEdit === false) {
      // ADD API CALL

      if (data.color === undefined) {
        delete data.color;
      }

      try {
        const res = await httpPost('projects', data);
        if (res.code === 1) {
          props.ListProject();
          props.setManageModal(false);
          dispatch(fetchProjects());
        } else if (res.code === 0) {
          props.enqueueSnackbar(res.message, { variant: 'error' });
        }
      } catch (error) {
        props.enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };
  const ListUsers = async () => {
    try {
      const res = await httpGet('users');
      if (res.code === 1) {
        const userList = res.data.data.filter((item) => item.isActive === true && item);
        setUserList(userList);
      } else if (res.code === 0) {
        enqueueSnackbar(res.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    ListUsers();
  }, []);
  const handleCheckSubClient = (e) => {
    setValue('client', e.target.value);
    clearErrors('client');
    props.clientList.filter((item) => {
      if (item._id === e.target.value) {
        setSubClientList(item.subClientName);
      }
      return item;
    });
  };

  const manageModal = (wantToEdit, itemData) => {
    setManageModal(true);
    setWantToEdit(wantToEdit);
    if (wantToEdit === true) {
      // EDIT DATA
      setLoadingEdit(true);

      const obj = {
        fullName: itemData.fullName,
        legalName: itemData.legalName,
        email: itemData.email,
        address: itemData.address,
        phoneNumber: itemData.phoneNumber,
      };
      setTimeout(() => {
        setLoadingEdit(false);
      }, 1000);
      setFormData(obj);
      setClientId(itemData._id);
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={props?.open}
        onClose={props?.onClose}
        PaperProps={{
          sx: { maxWidth: 720 },
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="div">{props.wantToEdit ? 'Edit' : 'Add'} Project</Typography>
            </Grid>
          </DialogTitle>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField name="projectName" label="Project Name" />
                  {/* <RHFSelect name="client" label="Select Client" onChange={handleCheckSubClient}>
                    <option value="" />
                    {props.clientList?.map((option, index) => (
                      <option key={index} value={option._id}>
                        {option.fullName}
                      </option>
                    ))}
                  </RHFSelect> */}

                  <RHFSelect name="client" label="Client" onChange={handleCheckSubClient}>
                    <MenuItem value="" />
                    <MenuItem
                      value=""
                      onClick={() => manageModal(false)}
                      style={{ fontWeight: 'bold' }}
                    >
                      {' '}
                      + Add New
                    </MenuItem>
                    {props.clientList?.map((option, index) => (
                      <MenuItem key={index} value={option._id}>
                        {option.fullName}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-checkbox-label">Users</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={values.users || []}
                      name="users"
                      onChange={(e) => setValue('users', e.target.value)}
                      input={<OutlinedInput label="Select Users" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected?.map((roleId) => (
                            <Chip
                              key={roleId}
                              label={userList?.find((e) => e._id === roleId)?.fullName}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {/* <MenuItem>
                        <Button onClick={handleAddUserPopupOpen}>Add New</Button>
                      </MenuItem> */}
                      {userList?.map((item, index) => (
                        <MenuItem value={item._id} key={item._id}>
                          <Checkbox checked={values?.users?.indexOf(item._id) > -1} />
                          {item.fullName}
                        </MenuItem>
                      ))}
                    </Select>

                    {/* Render your AddUserPopupComponent here */}
                    {/* {openAddUserPopup && <AddUserPopupComponent onClose={handleAddUserPopupClose} />} */}
                  </FormControl>
                  {values.client && (
                    <>
                      <RHFSelect
                        name="subClient"
                        label="Sub Client"
                        onChange={(e) => setValue('subClient', e.target.value)}
                      >
                        <MenuItem value="" />
                        {subClientList?.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </RHFSelect>
                    </>
                  )}
                  <RHFTextField name="fixedHours" label="Fixed Hours" />

                  <RHFTextField name="estimatedTime" label="Budgeted Hours" />
                  <RHFTextField
                    name="billableRate"
                    label="Hourly Rates"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <ColorSinglePicker
                        value={field.value || ''}
                        onChange={field.onChange}
                        colors={COLOR_OPTIONS}
                      />
                    )}
                  />
                </Box>

                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {!props.wantToEdit ? 'Create Project' : 'Save Changes'}
                  </LoadingButton>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Dialog>

      <Dialog open={manageToggleModal} onClose={handleCloseManageModal}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="div">{wantToEdit ? 'Edit' : 'Add'} Client</Typography>
            <IconButton
              onClick={handleCloseManageModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            ></IconButton>
          </Grid>
        </DialogTitle>
        <AddEditClient
          wantToEdit={wantToEdit}
          clientId={clientId}
          enqueueSnackbar={enqueueSnackbar}
          ListClient={ListClient}
          setManageModal={setManageModal}
          formData={formData}
        />
      </Dialog>
    </>
  );
}

ProjectQuickEditForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
