import React from 'react';
import { memo, useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useForm, Controller } from 'react-hook-form';

import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Icon as Iconify } from '@iconify/react';
import { useAuthContext } from 'src/auth/hooks';
import { m } from 'framer-motion';
import { useSnackbar } from 'notistack';
import { useSettingsContext } from 'src/components/settings';
import {
  fetchProjects,
  setTrackingData,
  stopTracking,
  fetchActivities,
} from '../../../redux/slices/common';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Typography,
  Stack,
  Divider,
  Grid,
  Card,
  IconButton,
  Tooltip,
  Container,
} from '@mui/material';
import { useSelector, useDispatch } from '../../../redux/store';
// import AccessAlarmIcon  from '@mui/icons-material/AccessAlarmIcon ';

import FormProvider, {
  RHFEditor,
  RHFUpload,
  RHFSwitch,
  RHFSlider,
  RHFCheckbox,
  RHFTextField,
  RHFRadioGroup,
  RHFMultiSelect,
  RHFAutocomplete,
  RHFMultiCheckbox,
} from 'src/components/hook-form';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useResponsive } from 'src/hooks/use-responsive';
import moment from 'moment';
import RHFSelect from 'src/components/hook-form/rhf-select-custom';
import { httpGet, httpPost, httpPut } from 'src/utils/apis';
import ProjectQuickEditForm from 'src/sections/projects/project-quick-edit-form';
import AddActivities from 'src/sections/overview/activities/addEditActivity';

function SearchbarHeader() {
  const { timerData, activityList, projectList } = useSelector((state) => state.common);
  const { user } = useAuthContext();
  // console.log('USER', user);

  const [timer, setTimer] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const increment = useRef(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [desc, setDesc] = useState('');
  const [formData, setFormData] = useState({});
  const [assignProject, setAssignProject] = useState([]);
  const [manageToggleModalforOpen, setManageModalforopen] = useState(false);
  const [wantToEdit, setWantToEdit] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [clientList, setClientList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subClientList, setSubClientList] = useState([]);
  const [userList, setUserList] = useState([]);
  const theme = useTheme();
  const [isNewSelected, setIsNewSelected] = useState(false);
  const [manageToggleModal, setManageModal] = useState(false);
  const [userStatus, setUserStatus] = useState();
  const [description, setDescription] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const activityClose = (e, reason) => {
    dispatch(fetchActivities('', values.project));

    if (reason === 'backdropClick') {
    } else {
      setIsNewSelected(false);
    }
  };
  const NewTaskSchema = Yup.object().shape({
    // project: Yup.string().required('Project is required').notOneOf(['-1'], 'Project is required'),
    // description: Yup.object()
    //   .shape({ description: Yup.string().required('Description is required') })
    //   .required('required'),
    // isBillable: Yup.boolean().optional(),
    // _id: Yup.string().optional()
  });

  useEffect(() => {
    dispatch(fetchProjects());
  }, []);

  const defaultValues = {
    description: timerData?.description?._id
      ? timerData?.description
      : activityList.find((x) => x.name === timerData.description),
    project: timerData?.project || '-1',
    isBillable: timerData?.isBillable || true,
    custom: timerData?.description?._id ? timerData?.description?.custom : '',
    user: '',
    _id: timerData?.trackingId || '',
  };
  const methods = useForm({
    resolver: yupResolver(NewTaskSchema),
    defaultValues,
  });
  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  const handleCloseManageModal = () => {
    setFormData([]);
    setManageModalforopen(false);
  };

  const ListProject = async () => {
    setLoading(true);
    try {
      const res = await httpGet(`projects`);
      if (res.code === 1) {
        const projList = res?.data?.data?.filter((item) => item.isActive === true && item);
        setAssignProject(projList);
        setLoading(false);
      } else if (res.code === 0) {
        enqueueSnackbar(res.message, { variant: 'error' });
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };
  const ListClient = async () => {
    try {
      const res = await httpGet('client');

      if (res.code === 1) {
        const clientList = res?.data?.data?.filter((item) => item?.isActive === true && item);
        setClientList(clientList);
      } else if (res.code === 0) {
        enqueueSnackbar(res.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  useEffect(() => {
    ListProject();
    ListClient();
  }, []);
  const fetchTimer = async () => {
    try {
      const ress = await httpGet('tracking/currentTask');
      if (ress.code) {
        const obj = {
          description: ress?.data?.data.description,
          project: ress?.data?.data?.project?._id,
          trackingId: ress.data?.data?._id,
          custom: ress?.data?.data?.custom,
          isBillable: ress.data?.data?.isBillable,
          timer: moment(ress?.data?.data.startTime),
        };
        if (ress?.data?.data._id) {
          dispatch(setTrackingData(obj));
        } else {
          dispatch(stopTracking());
        }
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    const timer12 = moment.duration(moment().diff(timerData.timer));

    if (timerData?.timer) {
      setIsActive(true);
      // props.setIsShowData(false);
      setTimer(parseInt(timer12.asSeconds(), 10));
      increment.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
      setValue(
        'description',
        timerData?.description?._id
          ? timerData?.description
          : activityList.find((x) => x.name === timerData.description)
      );
      setValue('project', timerData?.project);
      setValue('custom', timerData?.description?._id ? timerData?.description?.custom : '');
    } else {
      fetchTimer();
    }
  }, [timerData?.timer]);

  useEffect(() => {
    dispatch(fetchActivities('', values.project));
  }, [values.project]);

  const fetchUsers = async () => {
    try {
      const res = await httpGet(`users/listUsersDropDown`);
      if (res.code === 1) {
        setUserList(res.data.data);
      } else if (res.code === 0) {
        enqueueSnackbar(res.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
    return `${getHours}:${getMinutes}:${getSeconds}`;
  };
  const onSubmit = async () => {
    try {
      const obj = {
        description: values.description?.name,
        flag: 'start',
        task: '',
        assingee: user._id,
        project: values.project,
        isBillable: values.isBillable,
      };
      if (values.custom) {
        obj.custom = values.custom;
      }
      const res = await httpPost('tracking', obj);
      if (res.code === 1) {
        const obj = {
          description: values.description,
          project: values.project,
          trackingId: res.data?.data?.time_track_id,
          custom: res?.data?.data?.custom,
          timer: moment(),
        };
        dispatch(setTrackingData(obj));
        // enqueueSnackbar('Task Started!');
      } else if (res.code === 0) {
        console.error(res?.data?.message);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  const ListActivities = async () => {
    setLoading(true);
    try {
      const res = await httpPost('activity/getActivity', {});

      if (res.code === 1) {
        setLoading(false);
      } else if (res.code === 0) {
        enqueueSnackbar(res.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const stopTask = async () => {
    const obj = {
      flag: 'end',
      _id: timerData.trackingId,
      custom: values.custom,
      desc1: desc,
    };

    const res = await httpPut('tracking', obj);
    if (res.code === 1) {
      setDesc('');
      // setIsActive(false);
      // setTimeout(() => {
      //   setValue('description', '');
      //   setValue('project', '');
      // }, 3000);

      setTimer(0);
      clearInterval(increment.current);
      setTimeout(() => {
        dispatch(stopTracking());
      }, 500);
      setOpen(false);
      // enqueueSnackbar('Task Stopped');
    } else if (res.code === 0) {
      enqueueSnackbar(res.message, { variant: 'error' });
    }
  };

  const setUserId = async (e) => {
    try {
      const res = await httpPost(`users/setUser`, { userId: e.target.value });
      window.location.reload(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  const getUserId = async (e) => {
    try {
      const res = await httpGet(`users/getUserSelected`);
      if (res.code === 1) {
        setValue('user', res?.data?.data);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const currentDate = new Date().toString();
  const getUserStatus = async () => {
    try {
      // Get the current date

      // Create the payload with the current date
      const payload = { date: currentDate };

      // Pass the payload to the httpPost function
      const res = await httpPost(`users/checkAttendance`, payload);
      const status = res?.data?.status;
      if (res.code === 1) {
        setUserStatus(status);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleOpenPopup = () => setOpenPopup(true);
  const handleClosePopup = () => setOpenPopup(false);
  const handleDescriptionChange = (event) => setDescription(event.target.value);

  const handleEndMyDay = async () => {
    try {
      const checkTaskTimePayload = {
        date: currentDate,
      };
      const checkTaskTimeRes = await httpPost('users/checkTotalTaskTime', checkTaskTimePayload);

      if (!checkTaskTimeRes?.data?.status) {
        handleOpenPopup();
      } else {
        const payload = {
          date: currentDate,
          flag: 'end',
          description: '',
        };
        const res = await httpPost('users/createAttendance', payload);

        enqueueSnackbar(res?.data?.message, { variant: 'success' });
        getUserStatus();
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleSubmitDescription = async () => {
    try {
      if (!description || description.trim() === '') {
        enqueueSnackbar('Please enter description', { variant: 'error' });
        return;
      }
      const payload = {
        date: currentDate,
        flag: 'end',
        description: description,
      };
      const res = await httpPost('users/createAttendance', payload);
      enqueueSnackbar('Day Ended', { variant: 'success' });
      handleClosePopup();
      getUserStatus();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleStartMyDay = async () => {
    try {
      const payload = {
        date: currentDate,
        flag: 'start',
        description: '',
      };
      const res = await httpPost(`users/createAttendance`, payload);
      enqueueSnackbar('Day Started', { variant: 'success' });
      getUserStatus();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    getUserId();
    getUserStatus();
    // window.location.reload(false);
  }, []);

  return (
    <>
      {/* <Page title="Timer"> */}
      <Container>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} ml="-2.5rem">
                <Card
                  sx={{
                    m: 0,
                    p: 0,
                    background: 'transparent',
                    boxShadow: 'none',
                    // border: '1px solid #E4E7EB',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Grid item xs={4} ml={1}>
                    <FormControl variant="filled" sx={{ m: 1, minWidth: 160 }}>
                      <RHFSelect
                        disabled={timerData?.timer || false}
                        name="Project"
                        label={'Project'}
                        onChange={(e) => {
                          if (e.target.value === 'addNew') {
                            setManageModalforopen(true);
                          } else {
                            setValue('project', e.target.value);
                          }
                        }}
                      >
                        <option value={''}></option>
                        {user?.pageAccess?.some(
                          (page) =>
                            page.pageName === 'Projects' && page.access && page.access.create
                        ) && (
                          <option value={'addNew'} style={{ fontWeight: 'bold' }}>
                            + Add New{' '}
                          </option>
                        )}

                        {projectList.map((option, idx) => (
                          <option key={idx} value={option._id}>
                            {` ${option.projectName} - ${option?.client?.fullName} `}
                          </option>
                        ))}
                      </RHFSelect>
                    </FormControl>
                  </Grid>

                  <Grid item xs={24} ml={2}>
                    <FormControl variant="filled" sx={{ width: '100%' }}>
                      <Autocomplete
                        fullWidth
                        labelId="demo-multiple-checkbox-label"
                        id="checkboxes-tags-demo"
                        value={values.description}
                        disabled={values.project === '-1' || timerData?.timer || false}
                        name="description"
                        options={[
                          { name: '+ Add New', isNew: true }, // Add New option
                          ...(activityList?.filter(
                            (x) =>
                              x.project?.includes(timerData?.project) ||
                              x.project?.includes(values?.project) ||
                              x.project.length === 0
                          ) || []),
                        ]}
                        getOptionLabel={(option) => option.name || ''}
                        onChange={(e, value) => {
                          if (value?.isNew) {
                            setIsNewSelected(true);
                          } else {
                            setValue('description', value);
                            setValue('isBillable', value?.isBillable);
                            setValue('custom', value?.custom);
                            setIsNewSelected(false);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="What are you working on?"
                            placeholder="Search.."
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {(timerData?.custom || values?.description?.isCount) && (
                    <Grid item xs={2} sx={{ display: 'flex' }}>
                      <RHFTextField type="number" fullWidth name="custom" placeholder="value" />
                    </Grid>
                  )}
                  <Grid item xs={2} sx={{ display: 'flex' }}>
                    <IconButton disabled={timerData?.timer || false}>
                      <Tooltip
                        title={values?.isBillable ? 'Mark as Non-billable' : 'Mark as Billable'}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            width: '40px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            color: values?.isBillable ? 'white' : 'gray',
                            // border: values.isBillable ? 'none' : '2px solid #000000',
                            backgroundColor: (theme) =>
                              values.isBillable ? theme.palette.primary.main : 'white',
                            borderRadius: '5px',
                            textOverflow: 'ellipsis',
                          }}
                          onClick={(e) =>
                            user?.role?.role === 'ADMIN' || user?.role?.role === 'SUPERVISOR'
                              ? setValue('isBillable', !values.isBillable)
                              : ''
                          }
                        >
                          $
                        </Typography>
                      </Tooltip>
                    </IconButton>

                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                      <Divider
                        orientation="vertical"
                        sx={{ height: '50%', mx: 1, backgroundColor: 'gray' }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography
                      variant="h6"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'text.disabled',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Iconify
                        sx={{ width: 25, height: 25, marginRight: 1 }}
                        icon={'material-symbols:alarm'}
                      />
                      {formatTime()}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} ml={2}>
                    {timerData?.timer ? (
                      <m.div style={{ textAlign: 'right' }}>
                        <Tooltip title="Stop Time Entry ">
                          <Button
                            onClick={handleOpen}
                            size="large"
                            disabled={
                              timerData?.description?.custom
                                ? values.custom < parseInt(timerData?.description?.custom, 10)
                                : false
                            }
                            color="error"
                            style={{
                              width: 49,
                              height: 40,
                              minWidth: 80,
                              padding: 10,
                              borderRadius: 8, // Adjust the border radius as needed
                              backgroundColor: theme.palette.error.main,
                              color: theme.palette.error.contrastText,
                            }}
                            variant="contained"
                          >
                            <Iconify sx={{ width: 45, height: 45 }} icon={'eva:square-fill'} />
                          </Button>
                        </Tooltip>
                      </m.div>
                    ) : (
                      <m.div style={{ textAlign: 'right' }}>
                        <Tooltip title="Start Time Entry">
                          <Button
                            type="submit"
                            size="large"
                            disabled={
                              !values.description || values.project === '-1' || !values.project
                            }
                            style={{
                              width: 49,
                              height: 40,
                              minWidth: 80,
                              padding: 15,
                              borderRadius: 8,
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.primary.contrastText,
                              // Apply different styles for the disabled state
                              ...(values?.project === '-1' ||
                              !values?.project ||
                              !values.description
                                ? {
                                    // Add styles for the disabled state
                                    opacity: 0.5, // You can adjust the opacity or add other styles
                                    cursor: 'not-allowed',
                                  }
                                : {}),
                            }}
                            variant="contained"
                          >
                            <Iconify sx={{ width: 60, height: 60 }} icon={'eva:arrow-right-fill'} />
                            Start
                          </Button>
                        </Tooltip>
                      </m.div>
                    )}
                  </Grid>
                  <Grid item xs={2} ml={2}>
                    {userStatus ? (
                      <>
                        <Tooltip title="End My Day">
                          <Button
                            onClick={handleEndMyDay}
                            size="large"
                            disabled={
                              timerData?.description?.custom
                                ? values.custom < parseInt(timerData?.description?.custom, 10)
                                : false
                            }
                            color="error"
                            style={{
                              width: 49,
                              height: 40,
                              minWidth: 80,
                              padding: 10,
                              borderRadius: 8,
                              backgroundColor: theme.palette.error.main,
                              color: theme.palette.error.contrastText,
                            }}
                            variant="contained"
                          >
                            <Iconify
                              sx={{ width: 45, height: 45 }}
                              icon={'streamline:button-stop-solid'}
                            />
                          </Button>
                        </Tooltip>
                      </>
                    ) : (
                      <Tooltip title="Start My Day">
                        <Button
                          onClick={handleStartMyDay}
                          size="large"
                          disabled={
                            timerData?.description?.custom
                              ? values.custom < parseInt(timerData?.description?.custom, 10)
                              : false
                          }
                          color="error"
                          style={{
                            width: 49,
                            height: 40,
                            minWidth: 80,
                            padding: 10,
                            borderRadius: 8,
                            backgroundColor: theme.palette.info.main,
                          }}
                          variant="contained"
                        >
                          <Iconify sx={{ width: 45, height: 45 }} icon={'formkit:start'} />
                        </Button>
                      </Tooltip>
                    )}

                    <Dialog open={openPopup} onClose={handleClosePopup}>
                      <DialogTitle>End My Day</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Your working hours aren't complete yet. Are you sure you want to end your
                          day?
                        </DialogContentText>

                        {/* <TextField
                          autoFocus
                          margin="dense"
                          label="Description"
                          type="text"
                          fullWidth
                          value={description}
                          onChange={handleDescriptionChange}
                        /> */}

                        <TextField
                          autoFocus
                          placeholder="Enter Description"
                          multiline
                          rows={4}
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={description}
                          onChange={handleDescriptionChange}
                          sx={{ mt: 2 }}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClosePopup} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={handleSubmitDescription} color="primary">
                          Submit
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>

                  <Grid item xs={1}></Grid>
                </Card>
              </Grid>
            </Grid>
          </Stack>
        </FormProvider>
        <ProjectQuickEditForm
          open={manageToggleModalforOpen}
          onClose={handleCloseManageModal}
          assignProject={assignProject}
          formData={formData}
          wantToEdit={wantToEdit}
          setManageModal={setManageModalforopen}
          projectId={projectId}
          clientList={clientList}
          subClientList={subClientList}
          enqueueSnackbar={enqueueSnackbar}
          ListProject={ListProject}
        />
      </Container>
      {/* </Page>  */}
      <Dialog open={open} fullWidth onClose={handleClose}>
        <DialogTitle>Description</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            placeholder="Enter Description"
            multiline
            rows={4}
            type="text"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setDesc(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={stopTask}>Stop Task</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isNewSelected} onClose={activityClose}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="div">{wantToEdit ? 'Edit' : 'Add'} Activity</Typography>
            <IconButton
              onClick={activityClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <Iconify icon="ion:close" />
            </IconButton>
          </Grid>
        </DialogTitle>
        {/* {loadingEdit === true ? (
          <LoadingScreen />
        ) : ( */}
        <AddActivities
          wantToEdit={wantToEdit}
          formData={formData}
          projectList={projectList}
          ListActivities={ListActivities}
          setManageModal={setManageModal}
          enqueueSnackbar={enqueueSnackbar}
        />
        {/* )} */}
      </Dialog>
    </>
  );
}

export default memo(SearchbarHeader);
