// usePageAccess.js

import { useContext } from 'react';
import { useAuthContext } from 'src/auth/hooks';

const usePageAccess = (pageName) => {
  const { user } = useAuthContext();

  // Check if the user has access to the specified page
  const hasAccess = user?.pageAccess?.some((accessItem) => accessItem.pageName === pageName);

  return hasAccess;
};

export default usePageAccess;
