import React, { useMemo, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { Box, Card, Grid, Stack, Switch, Typography, FormControlLabel } from '@mui/material';
// @mui
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { m } from 'framer-motion';
import MuiPhoneNumber from 'mui-phone-number';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'src/components/hook-form/form-provider';
import { httpPost, httpPut } from 'src/utils/apis';
import { RHFTextField } from 'src/components/hook-form';
// import { FormProvider, RHFTextField } from '../../components/hook-form';
// import { httpPost, httpGet, httpPut } from '../../utils/apis';

function AddEditClient(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [phoneCode, setPhoneCode] = useState(props.formData.phoneCode || '+91');

  const defaultValue = useMemo(
    () => (props.formData ? props.formData : '-'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.formData]
  );
  const ClientSchema = Yup.object().shape({
    fullName: Yup.string().required('Full Name is required'),
    legalName: Yup.string().required('Legal Name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    phoneNumber: Yup.string()

      .matches(/^\d+$/, 'Only digits are allowed')
      .min(10)
      .max(13),
  });
  const methods = useForm({
    resolver: yupResolver(ClientSchema),
    defaultValue,
  });
  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  useEffect(() => {
    if (props.wantToEdit === true && props.formData) {
      reset(defaultValue);
    }
    if (props.wantToEdit === false) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.wantToEdit, props.formData]);

  const onSubmit = async (data) => {
    console.log('ddd', props);
    if (props.wantToEdit === true) {
      // EDIT API CALL
      console.log(' EDIT DATA', data);
      const obj = data;
      obj._id = props.clientId;
      obj.phoneCode = phoneCode;

      console.log(':DATA', obj);
      try {
        const res = await httpPut('client', obj);
        if (res.code === 1) {
          props.ListClient();
          props.setManageModal(false);
        } else if (res.code === 0) {
          props.enqueueSnackbar(res.message, { variant: 'error' });
        }
      } catch (error) {
        props.enqueueSnackbar(error.message, { variant: 'error' });
      }
    } else if (props.wantToEdit === false) {
      // ADD API CALL
      try {
        const obj = data;

        obj.phoneCode = phoneCode;
        const res = await httpPost('client', obj);
        if (res.code === 1) {
          props.ListClient();
          props.setManageModal(false);
        } else if (res.code === 0) {
          props.enqueueSnackbar(res.message, { variant: 'error' });
        }
      } catch (error) {
        props.enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };
  return (
    <div>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <RHFTextField name="fullName" label="Client Name" />
                <RHFTextField name="legalName" label="Legal Name" />
                <RHFTextField name="email" label="Email address" />
                <m.div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 10,
                    paddingBottom: '20px',
                  }}
                >
                  <MuiPhoneNumber
                    variant="outlined"
                    disabled
                    style={{ width: 160 }}
                    value={phoneCode}
                    placeholder={'Country Code'}
                    defaultCountry={'in'}
                    autoFormat={false}
                    onChange={(value) => setPhoneCode(value)}
                  />
                  <RHFTextField name="phoneNumber" label="Phone Number" />
                </m.div>

                {/* <RHFTextField name="address" label="Address" /> */}
              </Box>
              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {!props.wantToEdit ? 'Create Client' : 'Save Changes'}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </div>
  );
}

export default AddEditClient;
