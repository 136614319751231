import { useState, useEffect, useCallback, useMemo } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
// _mock
import { _userList, _roles } from 'src/_mock';
// hooks
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';
import { httpGet } from 'src/utils/apis';
import { LoadingScreen } from 'src/components/loading-screen';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import TimesheetTableRow from '../overview/reports/timesheets-table-row';
import TimeSheetTableToolbar from './timesheet-toolbar';
import { DatePicker } from '@mui/x-date-pickers';
// import { DatePicker } from '@mui/lab';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'timesheets',
    label: 'Timesheets',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  {
    value: 'billing',
    label: 'Attendance',
    icon: <Iconify icon="solar:bill-list-bold" width={24} />,
  },
  {
    value: 'notifications',
    label: 'Manual Time',
    icon: <Iconify icon="solar:bell-bing-bold" width={24} />,
  },
  {
    value: 'social',
    label: 'Scheduled Shift',
    icon: <Iconify icon="solar:share-bold" width={24} />,
  },
];

const STATUS_OPTIONS = [{ value: 'all', label: 'All' }];
const TABLE_HEAD = [
  { id: 'employeeName', label: 'Employee Name', align: 'left' },
  { id: 'location', label: '	Location', align: 'left' },
  { id: 'team', label: 'Team', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },

  { id: 'clockIn', label: 'Clock In', align: 'left' },
  { id: 'clockOut', label: 'Clock Out', align: 'center' },
  { id: 'workTime', label: 'Work Time', align: 'center' },
  { id: 'computerActivity', label: 'Computer Activity', align: 'center' },
  { id: 'manualTime', label: 'Manual Time', align: 'center' },
  { id: 'productive', label: 'Productive', align: 'center' },
  { id: 'unproductive', label: 'Unproductive', align: 'left' },

  { id: 'neutral', label: 'Neutral', align: 'left' },
  { id: 'idleTime', label: 'Idle Time', align: 'center' },
  { id: 'breakTime', label: 'Break Time', align: 'center' },
  { id: 'productivity', label: 'Productivity', align: 'center' },
];
export default function TimesheetTable() {
  const table = useTable();
  const styles = {
    selectMenu: {
      maxHeight: '200px', // Set the maximum height for the dropdown menu
      overflowY: 'auto', // Enable vertical scrolling if the menu exceeds maxHeight
    },
  };
  const settings = useSettingsContext();

  const [currentTab, setCurrentTab] = useState('timesheets');
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const defaultFilters = {
    fullName: '',
    role: [],
    status: 'all',
  };
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [userData, setUserData] = useState([]);

  const [assignGroup, setAssignGroup] = useState([]);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [loading, setLoading] = useState(false);

  const [filterName, setFilterName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const [filterRole, setFilterRole] = useState('all');
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [teamData, setTeamData] = useState();
  const [selectedTeam, setSelectedTeam] = useState('');

  const handleTeamChange = (event) => {
    setSelectedTeam(event.target.value);
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const { enqueueSnackbar } = useSnackbar();
  const filteredEmployeeData = useMemo(() => {
    return tableData.filter((row) =>
      row?.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, tableData]);
  const handleFilterCall = async (fromDate, toDate) => {
    try {
      setLoading(true);

      const res = await httpGet(
        `timeAttendance/getEmployeeAttendance?start_date=${fromDate ? fromDate : ''}&end_date=${
          toDate ? toDate : ''
        }&team=${selectedTeam ? selectedTeam : ''}`
      );
      setTableData(res?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleResetClick = () => {
    setFromDate(new Date());
    setToDate(new Date());
  };
  useEffect(() => {
    const ListTimesheet = async () => {
      try {
        setLoading(true);
        const res = await httpGet('timeAttendance/getEmployeeAttendance');
        setTableData(res?.data?.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };
    ListTimesheet();
  }, []);
  const ListUsers = async () => {
    try {
      setLoading(true);
      const res = await httpGet(`users?filter=`);
      if (res.code === 1) {
        setUserData(res.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const ListTeams = async () => {
    setLoading(true);
    try {
      const res = await httpGet('teams');
      if (res.code === 1) {
        setLoading(false);
        setTeamData(res.data.data);
      } else if (res.code === 0) {
        enqueueSnackbar(res.message, { variant: 'error' });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  useEffect(() => {
    ListUsers();
    ListTeams();
  }, []);
  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    // setSelected([]);
    setTableData(deleteRows);
  };

  const denseHeight = table?.dense ? 52 : 72;
  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(table?.order, table?.orderBy),
    filterName, // Pass filterName to applySortFilter function
  });
  const isNotFound =
    (!dataFiltered.length && !!filterName) || (!dataFiltered.length && !!filterRole);

  if (loading) {
    return <LoadingScreen />;
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClickReset = async () => {
    try {
      setLoading(true);

      const res = await httpGet(
        `timeAttendance/getEmployeeAttendance?start_date=${''}&end_date=${''}&team=${''}`
      );
      setTableData(res?.data?.data);
      setFromDate(new Date());
      setToDate(new Date());
      setSelectedTeam('');
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Time And Attendance "
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Time And Attendance', href: paths.dashboard.general?.attendance },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
      </Container>
      <Grid xs={12} md={12} lg={12}>
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
          <Card>
            <Tabs
              value={filters.status}
              sx={{
                px: 2.5,
                boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
              }}
            >
              {STATUS_OPTIONS.map((tab) => (
                <Tab
                  key={tab.value}
                  iconPosition="end"
                  value={tab.value}
                  label={tab.label}
                  icon={
                    <Label
                      variant={
                        ((tab.value === 'all' || tab.value === filters.status) && 'filled') ||
                        'success'
                      }
                    >
                      {tab.value === 'all' && tableData?.length}
                    </Label>
                  }
                />
              ))}
            </Tabs>

            <Stack
              spacing={2}
              alignItems={{ xs: 'flex-end', md: 'center' }}
              direction={{
                xs: 'column',
                md: 'row',
              }}
              sx={{
                p: 2.5,
                pr: { xs: 2.5, md: 1 },
              }}
            >
              {/* Date picker for 'From' date */}
              <DatePicker
                label="From Date"
                value={fromDate}
                onChange={handleFromDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder="Select From Date"
                  />
                )}
              />

              {/* Date picker for 'To' date */}
              <DatePicker
                label="To Date"
                value={toDate}
                onChange={handleToDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder="Select To Date"
                  />
                )}
              />
              <FormControl sx={{ minWidth: 240 }} variant="outlined">
                <InputLabel id="demo-select-small-label">Select Team</InputLabel>
                <Select
                  value={selectedTeam}
                  onChange={handleTeamChange}
                  label="Select Team"
                  MenuProps={{ PaperProps: { style: styles.selectMenu } }} // Apply styles to the menu
                >
                  <MenuItem value="">Select Team</MenuItem>
                  {teamData?.map((team) => (
                    <MenuItem key={team.id} value={team._id}>
                      {team.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button variant="contained" onClick={() => handleFilterCall(fromDate, toDate)}>
                  Apply
                </Button>
                <Button variant="outlined" onClick={handleClickReset}>
                  Reset
                </Button>
              </Box>
              {/* Search bar */}
              <TextField
                // fullWidth
                sx={{ ml: 3 }}
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table size={table?.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                  <TableHeadCustom
                    order={table?.order}
                    orderBy={table?.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={table?.selected.length}
                    onSort={table?.onSort}
                  />{' '}
                  <TableBody>
                    {filteredEmployeeData
                      .slice(
                        table.page * table.rowsPerPage,
                        table.page * table.rowsPerPage + table.rowsPerPage
                      )
                      .map((row) => (
                        <TimesheetTableRow
                          openCollapse={openCollapse}
                          assignGroup={assignGroup}
                          // key={index}
                          row={row}
                        />
                      ))}
                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table?.page, table?.rowsPerPage, tableData.length)}
                    />
                    <TableNoData notFound={isNotFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            <TablePaginationCustom
              count={dataFiltered.length}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </Card>

          {/* Spacing between rows */}
          <Box my={3} />
        </Container>
      </Grid>
    </>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName }) {
  let filteredData = [...tableData];

  if (filterName) {
    filteredData = filteredData.filter((item) =>
      item.fullName.toLowerCase().includes(filterName.toLowerCase())
    );
  }

  // Other filtering logic remains unchanged

  return filteredData;
}
