import React, { useMemo, useEffect, useState } from 'react';
import * as Yup from 'yup';

import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';

// @mui
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { m } from 'framer-motion';

// form

import { useForm, useWatch, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { fetchActivities } from 'src/redux/slices/common';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import RHFSelect from 'src/components/hook-form/rhf-select-custom';
import { httpPost, httpGet, httpPut } from 'src/utils/apis';
import ProjectQuickEditForm from 'src/sections/projects/project-quick-edit-form';
// import { useSelector, useDispatch } from '../../redux/store';
// import { fetchActivities } from '../../redux/slices/common';
// import { FormProvider, RHFTextField, RHFCheckbox, RHFSelect } from '../../components/hook-form';
// import { httpPost, httpGet, httpPut } from '../../utils/apis';

function AddActivities(props) {
  const [phoneCode, setPhoneCode] = useState(props.formData.phoneCode || '+91');
  const [showAddNewProject, setShowAddNewProject] = useState(false);
  const [formData, setFormData] = useState({});
  const [userId, setUserId] = useState('');
  const [assignProject, setAssignProject] = useState([]);
  const [manageToggleModalforOpen, setManageModalforopen] = useState(false);
  const [wantToEdit, setWantToEdit] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [clientList, setClientList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subClientList, setSubClientList] = useState([]);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValue = {
    project: props.formData.project || null,
    custom: props.formData.custom || null,
    description: props.formData.description || '',
    isBillable: props.formData.isBillable || false,
    isCount: props.formData.isCount || false,
    name: props.formData.name || null,
  };
  const ClientSchema = Yup.object().shape({
    name: Yup.string().required('Activity Name is required'),
    // description: Yup.string().required('Description is required'),
  });
  const methods = useForm({
    resolver: yupResolver(ClientSchema),
    defaultValue,
  });
  const {
    reset,
    setError,
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  const values = watch();

  useEffect(() => {
    if (props.wantToEdit === true && props.formData) {
      reset(defaultValue);
    }
    if (props.wantToEdit === false) {
      reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.wantToEdit, props.formData]);

  const onSubmit = async (data) => {
    if (props.wantToEdit === true) {
      // EDIT API CALL
      const obj = data;
      obj._id = props.activityId;
      try {
        const res = await httpPut('activity', obj);
        if (res.code === 1) {
          props.ListActivities();
          props.setManageModal(false);
          dispatch(fetchActivities());

          props.enqueueSnackbar('Updated Successfully', { variant: 'success' });
        }
      } catch (error) {
        props.enqueueSnackbar(error.message, { variant: 'error' });
      }
    } else if (props.wantToEdit === false) {
      // ADD API CALL
      try {
        const res = await httpPost('activity', data);
        if (res.code === 1) {
          props.ListActivities();
          props.setManageModal(false);

          props.enqueueSnackbar('Created Successfully', { variant: 'success' });
        }
      } catch (error) {
        props.enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };
  const handleCloseManageModal = () => {
    setFormData([]);
    setManageModalforopen(false);
  };

  const ListProject = async () => {
    setLoading(true);
    try {
      const res = await httpGet(`projects`);
      if (res.code === 1) {
        const projList = res?.data?.data?.filter((item) => item.isActive === true && item);
        setAssignProject(projList);
        setLoading(false);
      } else if (res.code === 0) {
        enqueueSnackbar(res.message, { variant: 'error' });
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };
  const ListClient = async () => {
    try {
      const res = await httpGet('client');

      if (res.code === 1) {
        const clientList = res?.data?.data?.filter((item) => item?.isActive === true && item);
        setClientList(clientList);
      } else if (res.code === 0) {
        enqueueSnackbar(res.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  useEffect(() => {
    // ListGroup();
    ListProject();
    ListClient();
  }, []);

  return (
    <div>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              {/* <RHFSelect
                style={{ marginBottom: 16 }}
                name="project"
                label="Select Project"
                onChange={(e) => setValue('project', e.target.value)}
              >
                <option value="" />
                {props.projectList.map((option, index) => (
                  <option key={index} value={option._id}>
                    {`${option.projectName}`}
                  </option>
                ))}
              </RHFSelect> */}
              <FormControl sx={{ mb: 3, width: '100%' }}>
                <InputLabel id="demo-multiple-checkbox-label">Select Project</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={values.project || []}
                  name="project"
                  onChange={(e) => {
                    console.log('setManageModalforopen', e.target.value);
                    if (e.target.value.includes('addNew')) {
                      setManageModalforopen(true);
                    } else {
                      setValue('project', e.target.value);
                    }
                  }}
                  input={<OutlinedInput label="Select Project" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((roleId) => (
                        <Chip
                          key={roleId}
                          label={props.projectList?.find((e) => e._id === roleId)?.projectName}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: '400px', // Adjust the maximum height as per your requirement
                      },
                    },
                  }}
                >
                  <MenuItem value="addNew" sx={{ fontWeight: 'bold' }}>
                    + Add New
                  </MenuItem>
                  {props.projectList.map((option, index) => (
                    <MenuItem value={option._id} key={option._id}>
                      <Checkbox checked={values?.project?.indexOf(option._id) > -1} />
                      {option.projectName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <RHFTextField name="name" label="Activity Name" /> <br /> <br />
              <RHFTextField name="description" label="Description" multiline rows={3} />
              <FormControlLabel
                label={'Is Billable'}
                sx={{ mt: 3 }}
                control={
                  <Controller
                    name="isBillable"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value || false}
                        value={field.value || ''}
                        onChange={field.onChange}
                      />
                    )}
                  />
                }
              />
              <FormControlLabel
                label={'Do You want Count'}
                sx={{ mt: 3 }}
                control={
                  <Controller
                    name="isCount"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value || false}
                        value={field.value || ''}
                        onChange={field.onChange}
                      />
                    )}
                  />
                }
              />
              {/* <RHFCheckbox name="isBillable" label="Is Billable" sx={{ mt: 3 }} /> */}
              {/* <RHFCheckbox name="isCount" label="Do You want Count" sx={{ mt: 3 }} /> */}
              {values?.isCount && (
                <RHFTextField type="number" name="custom" label="Enter minimim value" />
              )}
              {/* <RHFTextField name="address" label="Address" /> */}
              {/* </Box> */}
              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {!props.wantToEdit ? 'Create Activity' : 'Save Changes'}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
        <ProjectQuickEditForm
          open={manageToggleModalforOpen}
          onClose={handleCloseManageModal}
          assignProject={assignProject}
          formData={formData}
          wantToEdit={wantToEdit}
          setManageModal={setManageModalforopen}
          userId={userId}
          projectId={projectId}
          clientList={clientList}
          subClientList={subClientList}
          enqueueSnackbar={enqueueSnackbar}
          ListProject={ListProject}
        />
      </FormProvider>
    </div>
  );
}

export default AddActivities;
