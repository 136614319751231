// @mui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Checkbox,
  TableRow,
  TableCell,
  Stack,
  Box,
  Typography,
  Tooltip,
  Link,
  ListItemText,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function TimesheetTableRow({ row }) {
  console.log('row', row);
  const navigate = useNavigate();

  // const localClockIn = row.clock_in ? moment(row.clock_in, 'HH:mm').local().format('hh:mm A') : '-';
  const localClockIn = row.clock_in ? moment(new Date(row.clock_in)).format('hh:mm A') : '-';
  const localClockOut =
    row.clock_out !== '-' ? moment(new Date(row.clock_out)).format('hh:mm A') : '-';
  // const localClockOut =
  //   row.clock_out !== '-' ? moment(row.clock_out, 'HH:mm').local().format('hh:mm A') : '-';
  const theme = useTheme();
  const handleClick = (row) => {
    navigate(paths?.dashboard?.user?.user_productivity, { state: row });
  };
  const getInitials = (name) => {
    const initials = name
      .split(' ')
      .map((word) => word.charAt(0))
      .join('');
    return initials.toUpperCase();
  };
  return (
    <>
      <TableRow sx={{ whiteSpace: 'nowrap' }} hover>
        {/* <TableCell>
          <Tooltip title={`${row.employee} - ${row.department}`}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Box>
                <Typography variant="subtitle2">{row.employee}</Typography>
                <Link
                  onClick={() => handleClick(row)}
                ></Link>
                <Typography
                  variant="caption"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.text.secondary,
                  }}
                >
                  <Iconify icon="mdi:microsoft-teams" width={14} sx={{ mr: 0.5 }} />
                  {row.department}
                </Typography>
              </Box>
            </Stack>
          </Tooltip>
        </TableCell> */}

        <TableCell sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
          <Avatar alt={row.employee} src={row.avatarUrl} sx={{ mr: 1 }}>
            {getInitials(row.employee)}
          </Avatar>
          <Link
            onClick={() => handleClick(row)}
            //  to={paths?.dashboard?.general?.user_productivity}
            // href="/sections/user/user-productivity"
          >
            <ListItemText
              primary={row.employee}
              secondary={
                <Typography
                  variant="caption"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.text.secondary,
                  }}
                >
                  <Iconify icon="mdi:microsoft-teams" width={14} sx={{ mr: 0.5 }} />
                  {row.department}
                </Typography>
              }
              primaryTypographyProps={{ typography: 'body2' }}
              secondaryTypographyProps={{
                component: 'span',
                color: 'text.disabled',
              }}
              sx={{ minSize: 24, cursor: 'pointer' }}
            />
          </Link>
        </TableCell>

        <TableCell>{row.location}</TableCell>
        <TableCell>{row.department}</TableCell>
        <TableCell>{row.date}</TableCell>
        <TableCell>{localClockIn}</TableCell>
        <TableCell>{localClockOut}</TableCell>
        <TableCell>{row.work_time}</TableCell>
        <TableCell>{row.active_time}</TableCell>
        <TableCell>{row.manual_time}</TableCell>
        <TableCell>{row.productive_time}</TableCell>
        <TableCell>{row.unproductive_time}</TableCell>
        <TableCell>{row.neutral_time}</TableCell>
        <TableCell>{row.idle_time}</TableCell>
        <TableCell>{row.break_time}</TableCell>
        <TableCell>{row.productivity}%</TableCell>
      </TableRow>
    </>
  );
}
