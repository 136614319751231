import moment from 'moment';

export const timeDifference = (startTime, endTime) => {
  return moment
    .utc(moment(endTime).diff(moment(startTime)))
    .format('HH:mm:ss')
    .toString();
};

export const getCurrentWeekDays = () => {
  const weekStart = moment().subtract(7, 'd');
  const days = [];
  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days').format('DD-MM-YYYY'));
  }
  return days;
};

export const sumTime = (times) => {
  const result = moment.duration();

  times.forEach((time) => {
    result.add(moment.duration(time));
  });

  const hours = Math.floor(result.asHours());
  const mins = Math.floor(result.asMinutes()) - hours * 60;
  const sec = Math.floor(result.asSeconds()) - hours * 60 * 60 - mins * 60;
  return `${hours <= 9 ? `0${hours}` : hours}:${mins <= 9 ? `0${mins}` : mins}:${
    sec <= 9 ? `0${sec}` : sec
  }`;
};

export const getCurrentMonth = () => {
  const start = moment().startOf('month');
  const totalDays = moment().daysInMonth();
  const days = [];
  for (let i = 0; i < totalDays; i++) {
    days.push(moment(start).add(i, 'days').format('DD/MM'));
  }
  return days;
};

export const getYear = () => {
  return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
};
export const isSameDate = (sDate, heDate) => {
  const startDate = moment(sDate);
  const headerDate = moment(heDate);

  return startDate.isSame(heDate, 'day');
};
export const color = () => {
  const code = [
    '#FF6633',
    '#FFB399',
    '#FF33FF',
    '#FFFF99',
    '#00B3E6',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#99FF99',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
  ];
  const no = Math.floor(Math.random() * (50 + 1));
  return code[no];
};
