import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, FormHelperText, TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any, // Add propType for value
};

export default function RHFSelect({
  name,
  label,
  onChange,
  placeholder,
  value,
  multiple,
  children,
  ...other
}) {
  const { control } = useFormContext();

  return (
    // <Controller
    //   name={name}
    //   control={control}
    //   render={({ field, fieldState: { error } }) => (
    //     <TextField
    //       {...field}
    //       onChange={onChange}
    //       select
    //       fullWidth
    //       label={label} // Conditionally render the label
    //       InputLabelProps={value ? { shrink: true } : {}} // Shrink label when a value is selected
    //       SelectProps={{ native: true }}
    //       error={!!error}
    //       helperText={error?.message}
    //       {...other}
    //     >
    //       {children}
    //     </TextField>
    //   )}
    // />
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box display="flex" flexDirection="column" alignItems="stretch">
          <TextField
            {...field}
            select
            fullWidth
            placeholder={placeholder}
            label={label}
            InputLabelProps={field.value ? { shrink: true } : {}}
            SelectProps={{ native: true }}
            error={!!error}
            onChange={typeof onChange === 'function' ? onChange : null}
            // helperText={error ? error.message : ''}
            {...other}
          >
            {children}
          </TextField>
          {error && (
            <Box ml={'0.5rem'}>
              <FormHelperText error>{error.message}</FormHelperText>
            </Box>
          )}
        </Box>
      )}
    />
  );
}
