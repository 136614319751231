import PropTypes from 'prop-types';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
// auth
import { useAuthContext } from 'src/auth/hooks';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';
// components
import Logo from 'src/components/logo';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

const METHODS = [
  {
    id: 'jwt',
    label: 'Jwt',
    path: paths.auth.jwt.login,
    icon: '/assets/icons/auth/ic_jwt.svg',
  },
  {
    id: 'firebase',
    label: 'Firebase',
    path: paths.auth.firebase.login,
    icon: '/assets/icons/auth/ic_firebase.svg',
  },
  {
    id: 'amplify',
    label: 'Amplify',
    path: paths.auth.amplify.login,
    icon: '/assets/icons/auth/ic_amplify.svg',
  },
  {
    id: 'auth0',
    label: 'Auth0',
    path: paths.auth.auth0.login,
    icon: '/assets/icons/auth/ic_auth0.svg',
  },
];

export default function AuthClassicLayout({ children, image, title, otp, verify, createAccount }) {
  const { method } = useAuthContext();

  const theme = useTheme();
  const [toggle, setToggle] = useState(true);
  // const [text, setText] = useState('Hi, Welcome back 👋');
  const [text, setText] = useState(`Let's make progress together! 🌟`);

  // useEffect(() => {
  //   const textSet1 = 'Start tracking your productivity';
  //   const textSet2 = '👋 Explore your productivity stats.';
  //   const words = toggle ? textSet1 : textSet2;

  //   let currentIndex = 0;
  //   let interval;

  //   const typeText = () => {
  //     setText(words.substring(0, currentIndex) + '|');
  //     currentIndex++;

  //     if (currentIndex > words.length) {
  //       clearInterval(interval);
  //       setTimeout(() => {
  //         interval = setInterval(deleteText, 50);
  //       }, 1000); // Wait for 1 second before deleting
  //     }
  //   };

  //   const deleteText = () => {
  //     setText(words.substring(0, currentIndex) + '|');
  //     currentIndex--;

  //     if (currentIndex < 0) {
  //       clearInterval(interval);
  //       setTimeout(() => {
  //         currentIndex = 0;
  //         setToggle((prevToggle) => !prevToggle);
  //         interval = setInterval(typeText, 50);
  //       }, 500); // Wait for 0.5 seconds before typing again
  //     }
  //   };

  //   interval = setInterval(typeText, 50);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [toggle]);
  const mdUp = useResponsive('up', 'md');

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 560,
        px: { xs: 2, md: 8 },
        pt: { xs: 15, md: 20 },
        pb: { xs: 15, md: 0 },
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_3.jpg',
        }),
      }}
    >
      <Typography
        variant="h3"
        sx={{
          maxWidth: 550,
          textAlign: 'center',
          mt: 3,
        }}
      >
        {!createAccount && text}
      </Typography>

      <Box
        component="img"
        alt="auth"
        src={image || '/assets/illustrations/beam.gif'}
        sx={{
          maxWidth: {
            xs: 480,
            lg: 560,
            xl: 720,
          },
        }}
      />
    </Stack>
  );
  const renderSectionOtp = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_4.jpg',
        }),
      }}
    >
      <Box
        component="img"
        alt="auth"
        src={image || '/assets/illustrations/otp2.gif'}
        sx={{
          maxWidth: {
            xs: 480,
            lg: 560,
            xl: 720,
          },
        }}
      />
    </Stack>
  );
  const renderSectionAccount = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_4.jpg',
        }),
      }}
    >
      <Box
        component="img"
        alt="auth"
        src={image || '/assets/illustrations/createAccount.gif'}
        sx={{
          maxWidth: {
            xs: 480,
            lg: 400,
            xl: 600,
          },
        }}
      />
    </Stack>
  );
  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderLogo}

      {!otp && !createAccount && mdUp && renderSection}
      {otp && mdUp && renderSectionOtp}
      {createAccount && mdUp && renderSectionAccount}

      {renderContent}
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
